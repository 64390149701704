import React from "react"
import Layout from '../components/layout';
import { graphql } from "gatsby"
import Img from "gatsby-image"

export default function Home({data}) {
  console.log('data.file', data.file);
  return <Layout>
    <h2>About us</h2>
    <p>
      Sydney based software and web consultancy.
    </p>
    <Img fluid={data.file.childImageSharp.fluid} />
    <h2>Services</h2>
    <ul>
      <li>Development leadership</li>
      <li>Sitecore architecture and development</li>
      <li>Nativescript development</li>
      <li>Devops services</li>
      <li>Automated testing implementation</li>
    </ul>
    <h2>Our team</h2>
    <ul>
      <li>David Huby <a href="https://www.linkedin.com/in/david-huby/">LinkedIn</a></li>
      <li>Jin Hee Jeong</li>
    </ul>
    <h2>Our team's recent experience</h2>
    <ul>
      <li>A member portal and complex online forms implemented with Sitecore JSS and Angular</li>
      <li>A website, iOS app, and Android app implemented using Nativescript and Angular, and Continuous integration in Jenkins</li>
    </ul>
    <h2>Contact us</h2>
    <p>
      Contact us via <a href="https://www.linkedin.com/in/david-huby/">LinkedIn</a>.
    </p>
  </Layout>
}

export const query = graphql`
  query {
      file(relativePath: { eq: "sydney-air-jamie-davies-unsplash.jpg" }) {
          childImageSharp {
              fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
              }
          }
      }
  }
`


